:root{
  --num-width:1%;
  --name-width:6%;
  --header-width:12%;
  /* --header-width:calc((70% - var(--num-width) - var(--name-width)) / 4);  */
}

body {
  margin: auto ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:.7em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{ 
  text-align: left;
  letter-spacing: 2px;
  font-size: 2em;
  margin: 2px auto; 
}

span.green {
  display:inline-block;
  height:22px;
  background-color:rgb(105, 189, 105);
  border-radius: 5px;
  padding:2px;
  margin: 4px 5px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

th {
  background-color:rgb(211, 253, 211);
  height:2em;
  text-align:center;
}

th.num {
  width:var(--num-width);
}

th.name {
  width:var(--name-width);
  text-align:center;
}

th.header1 {
  letter-spacing:10px;
  width:var( --header-width);
}

th.hanon, td.hanon {
  width:1%;
}

td {
  padding:3px 10px;
  text-align:left;
}

td.num{
  color:rgb(50, 161, 50);
}

button.numButton{
  border-radius:50%;
  border: 3px outset rgb(255, 202, 202);
  color:rgb(50, 161, 50);

}

button {
  margin: 2px 2px;
  border-radius: 5px;
  border-width:0px;
}

button.yellow {
  background-color:rgb(255, 255, 0);
}
button.crimson {
  background-color:rgb(255, 194, 206);
}
button.green {
  background-color:rgb(209, 255, 209);
}
button.blue {
  background-color:rgb(210, 210, 255);
}
button.violet {
  background-color:rgb(255, 227, 255);
}

button.cyan {
  background-color:rgb(211, 252, 255);
}

button.gray {
  background-color:rgb(235, 235, 235);
}

@media print {
.noPrint {
  display: none;
}
}